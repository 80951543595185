// - - - - - - - - - - - - - - - - - -

// Socials

// - - - - - - - - - - - - - - - - - -



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Socials

.socials {
	list-style: none;
	margin: 0;
	padding: 0;
}

.socials__item {
	display: inline-block;
	margin-left: 2px;

	&:first-child {
		margin-left: 0;
	}
}

.socials__item__link {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background: $text-light-color;
	font-size: 20px;
	line-height: 1;
	color: $background-color;

	@include mq(tabletp) {
		width: 50px;
		height: 50px;
		font-size: 25px;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		@include translate(-50%,-52%);
	}

	&:hover {
		background: $accent-color;
		color: $background-color;
	}
}