// - - - - - - - - - - - - - - - - - -

// Footer

// - - - - - - - - - - - - - - - - - -


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Footer

.footer {
  @include transition(all 0.4s ease-in-out 0.8s);
  padding: 80px 0;

  @include mq(phonel) {
    padding: 40px 0;
  }

  @include mq(tabletp) {
    padding: 50px 0;
  }

  @include mq(tabletl) {
    padding: 60px 0;
  }
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);

}

.footer_logos {
    grid-column: span 2; 
    grid-row: span 2; 
}

.footer_links {
  grid-column: span 2 / -1; 
  grid-row: span 1;
}

.footer__logo {
  display: block;
  width: $footer-logo-width;
}

.footer__title {
  font-size: $sitetitle-small;
  font-weight: $sitetitle-weight;
  letter-spacing: $sitetitle-spacing;
  line-height: 1;
  color: $text-medium-color;

  &:hover {
    color: $text-dark-color;
  }

  @include mq(tabletp) {
    font-size: $sitetitle-medium;
  }

  @include mq(laptop) {
    font-size: $sitetitle-large;
  }

  @include mq(desktop) {
    font-size: $sitetitle-large;
  }
}

.footer__text {
  color: $text-light-color;
  font-size: 13px;

  span {
    margin-top: 10px;
    display: inline-block;
  }
}

.footer__text-wrapper {
  display: block;
  margin-top: 10px;
}

.footer .socials {
  margin-left: 10px;
}
.footer-socials {
  grid-column: span 1;
  grid-row: span 2; 
  align-self: center; 
  ul {
    line-height: 0;
  }
}

.footer-copyright {
  display: block;
  grid-column: 1 / span 4;
  grid-row: span 2; 
}

.footer-mininav {
  display: block;
  grid-column: 1 / span 1;
  grid-row: span 4;
  text-align: left;
  margin-top: 10px;
  a {
    color: $text-light-color;
    font-weight: $regular-weight;

    &:hover {
      color: $text-medium-color;
    }
  }

  @include mq(tabletl) {
    a {
      margin-left: 10px;
      }
    grid-column: span 4/ -1; 
    text-align: right;
  }

  @include mq(laptop) {
    a {
      margin-left: 10px;
      }
    grid-column: span 4/ -1; 
    text-align: right;
  }

  @include mq(desktop) {
    a {
      margin-left: 10px;
      }
    grid-column: span 4 / -1; 
    text-align: right;
  }
}

.nav-item {
  display: inline-block;
  margin-left: 0px;

  @include mq(tabletl) {
    &:first-child {
      margin-left: 2px;
    }
  }

  @include mq(laptop) {
    &:first-child {
      margin-left: 2px;
    }
  }

  @include mq(desktop) {
    &:first-child {
      margin-left: 2px;
    }
  }
}